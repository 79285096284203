export const departments = [
  'AERO',
  'BIO',
  'IT',
  'CHEM',
  'MATH',
  'ARCH',
  'ENGL',
  'PHIL',
  'AGB',
  'ASCI',
  'ART',
  'EE',
  'BUS',
  'PHYS',
  'SOC',
  'CSC',
  'JOUR',
  'FSN',
  'IME',
  'DSCI',
  'POLS',
  'SS',
  'CE',
  'TH',
  'ARCE',
  'LA',
  'MU',
  'ME',
  'COMS',
  'NRM',
  'STAT',
  'ECON',
  'EDUC',
  'HIST',
  'CPE',
  'CRSC',
  'PSY',
  'WGS',
  'CRP',
  'ES',
  'MSC',
  'EHS',
  'AGED',
  'HUM',
  'BRAE',
  'GRC',
  'MATE',
  'LS',
  'CM',
  'PE',
  'ENGR',
  'BMED',
  'SCI',
  'GBA',
  'WS',
].sort();
